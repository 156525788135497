<template>
  <div class="analysis-container">
    <div class="data-trend" style="margin-top: 50px">
      <!-- 主推品类头部 -->
      <div class="data-menu-box">
        <span class="title" style="font-size: 16px; font-weight: bold"
          >主推品类</span
        >
        <div class="data-menu">
          <div
            class="item left"
            :class="{ selected: fanTabIndex === 1 }"
            @click="toggleFanTab(1)"
          >
            7天
          </div>
          <div
            class="item right"
            :class="{ selected: fanTabIndex === 2 }"
            @click="toggleFanTab(2)"
          >
            15天
          </div>
          <div
            class="item right"
            :class="{ selected: fanTabIndex === 3 }"
            @click="toggleFanTab(3)"
          >
            30天
          </div>
        </div>
        <!-- 日期选择 -->
        <div class="data-menu">
          <el-date-picker
            v-model="dateSelection"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateSelectionFun"
            value-format="yyyy-MM-dd"
            >
          </el-date-picker>
        </div>
      </div>
      <!-- 表格种类 -->
      <div class="table-selected">
        <div class="table-tabs">
          <div
            class="top-tab"
            :class="{ active: goodsType === 1 }"
            style="font-size: 16px; margin-right: 10px"
            @click="changTableData(1)"
          >
            商品品类
          </div>
          <div style="margin-right: 10px">|</div>
          <div
            class="top-tab"
            :class="{ active: goodsType === 3 }"
            style="font-size: 16px; margin-right: 10px"
            @click="changTableData(3)"
          >
            商品品牌
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table
          :data="taskList"
          class="college-table"
          style="width: 100%; margin-top: 20px"
          size="medium"
          stripe
          @sort-change="changeOrder"
          :header-cell-style="{
            fontWeight: 'Regular',
            color: '#333',
            background: '#fff',
          }"
          :cell-style="{ fontSize: '12px', color: '#333' }"
        >
          <!-- 商品 -->
          <el-table-column
            prop="tag_name"
            label="商品品类"
            align="left"
            width="500"
            v-if="goodsType == '1'"
          >
          </el-table-column>
          <el-table-column
            prop="first"
            label="商品品类"
            align="left"
            width="500"
            v-if="goodsType == '2'"
          >
          </el-table-column>
          <el-table-column
            prop="tag_name"
            label="商品品牌"
            align="left"
            width="500"
            v-if="goodsType == 3"
          >
          </el-table-column>
          <!-- 均价 -->
          <el-table-column prop="avg_price" label="均价" align="center">
            <template slot-scope="scope">
              <span style="font-size: 14px; font-weight: bold; color: red"
                >￥{{ scope.row.avg_price }}</span
              >
            </template>
          </el-table-column>
          <!-- 商品数 -->
          <el-table-column prop="product_count" label="商品数" align="center" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.product_count }}</span>
            </template>
          </el-table-column>
          <!-- 销量 -->
          <el-table-column
            prop="volume"
            label="销量"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.volume }}</span>
            </template>
          </el-table-column>
          <!-- 销售额 -->
          <el-table-column
            prop="amount"
            label="销售额"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span
                >{{ (scope.row.amount / 10000).toFixed(2) }}w<span
                  style="color: #999"
                  >({{ scope.row.percent }}%)</span
                ></span
              >
            </template>
          </el-table-column>
          <!-- 平均转化率 -->
          <el-table-column
            prop="avg_convert_rate"
            label="平均转化率"
            align="center"
         
          >
            <template slot-scope="scope">
              <span>{{ scope.row.avg_convert_rate }}%</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 销量趋势图头部 -->
      <div class="data-menu-box" style="margin-top: 100px">
        <span class="title" style="font-size: 16px; font-weight: bold"
          >销量趋势图</span
        >
        <div class="data-menu">
          <div
            class="item left"
            :class="{ selected: salesTabIndex === 1 }"
            @click="toggleSalesTab(1)"
          >
            7天
          </div>
            <div
            class="item right"
            :class="{ selected: salesTabIndex === 2 }"
            @click="toggleSalesTab(2)"
          >
            15天
          </div>
          <div
            class="item right"
            :class="{ selected: salesTabIndex === 3 }"
            @click="toggleSalesTab(3)"
          >
            30天
          </div>
        </div>
      </div>
      <!-- 销量趋势图主体 -->
      <div class="sales-echarts" style="margin-top: 15px">
        <div
          class="sales-main"
          id="sales-main-echarts"
          style="height: 420px; width: 100%"
        ></div>
      </div>
      <!-- 销售额趋势图头部 -->
      <div class="data-menu-box" style="margin-top: 50px">
        <span class="title" style="font-size: 16px; font-weight: bold"
          >销售额趋势图</span
        >
        <div class="data-menu">
          <div
            class="item left"
            :class="{ selected: salesVolumeTabIndex === 1 }"
            @click="toggleSalesVolumeTab(1)"
          >
            7天
          </div>
            <div
            class="item right"
            :class="{ selected: salesVolumeTabIndex === 2 }"
            @click="toggleSalesVolumeTab(2)"
          >
            15天
          </div>
          <div
            class="item right"
            :class="{ selected: salesVolumeTabIndex === 3 }"
            @click="toggleSalesVolumeTab(3)"
          >
            30天
          </div>
        </div>
      </div>
      <!-- 销售额趋势图主体 -->
      <div class="sales-echarts" style="margin-top: 15px; margin-bottom: 20px">
        <div
          class="sales-main"
          id="salesVolume-main-echarts"
          style="height: 420px; width: 100%"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { showSellGoods} from "@/utils/apis";
export default {
  props: ["author_id"],
  data() {
    return {
      fanTabIndex: 1, //近7天/30天切换
      salesTabIndex: 1, //销量趋势图 近7天/30天切换
      salesVolumeTabIndex: 1, //销量额趋势图 近7天/30天切换
      goodsType: 1, // 1经营大类 2细分品类 3商品品牌
      orderType: "", //需要排序的字段
      sortType: "", //排序方式
      taskList: [], //主推品类列表
      salesEchartData: [], //销售趋势图表数据
      salesVolumeEchartData: [], //销售额趋势图表数据
      salesTrend: null,
      salesVolume: null,
      dateSelection:[],
      goodsType_two:"",
      sort:'', //排序字段
      start_date:'', //开始时间
      end_date:'', //结束时间
      timer:''//转换日期
    };
  },
  mounted() {
    this.time_default()
    this.init();
  },
  methods: {
    //时间戳转日期
    timetransition(value){
      if (value == null) {
              return ;
          } else {
              let date = new Date(value);
              let y = date.getFullYear();// 年
              let MM = date.getMonth() + 1;// 月
              MM = MM < 10 ? ('0' + MM) : MM;
              let d = date.getDate();// 日
              d = d < 10 ? ('0' + d) : d;
              // let h = date.getHours();// 时
              // h = h < 10 ? ('0' + h) : h;
              // let m = date.getMinutes();// 分
              // m = m < 10 ? ('0' + m) : m;
              // let s = date.getSeconds();// 秒
              // s = s < 10 ? ('0' + s) : s;
              var timer =  y + '-' + MM + '-' + d;
              this.timer = timer 
          }
      },

    //日期默认
    time_default(){
      this.dateSelection = []
      let start_time = new Date().setDate((new Date().getDate()-6))
      let end_time = new Date()
      this.timetransition(start_time)
      this.dateSelection.push(this.timer)
      this.timetransition(end_time)
      this.dateSelection.push(this.timer)
    },

    //   tabs切换，显示相应数据
    changTableData(val) {
      this.goodsType = val;
      if(val ==1){
        this.goodsType_two = 'category'
      }else if(val ==3){
        this.goodsType_two = 'brand'
      }
       console.log(this.goodsType_two,"品类")
    },
    //   天数切换并显示相应数据
    toggleFanTab(index) {
      this.fanTabIndex = index;
       let start_time = ''
       let end_time = new Date()
      if(this.fanTabIndex ==1){
        this.dateSelection = []
        start_time = new Date().setDate((new Date().getDate()-6))
        this.timetransition(start_time)
        this.dateSelection.push(this.timer)
        this.timetransition(end_time)
        this.dateSelection.push(this.timer)
        this.toggleSalesTab(1)
        this.toggleSalesVolumeTab(1)
        // console.log(this.dateSelection,"88888")
      }else if(this.fanTabIndex ==2){
        this.dateSelection = []
        start_time = new Date().setDate((new Date().getDate()-14))
        this.timetransition(start_time)
        this.dateSelection.push(this.timer)
        this.timetransition(end_time)
        this.dateSelection.push(this.timer)
           this.toggleSalesTab(2)
           this.toggleSalesVolumeTab(2)
        // console.log(this.dateSelection,"88888")
      }else if(this.fanTabIndex ==3){
        this.dateSelection = []
        start_time = new Date().setDate((new Date().getDate()-29))
        this.timetransition(start_time)
        this.dateSelection.push(this.timer)
        this.timetransition(end_time)
        this.dateSelection.push(this.timer)
        console.log(this.dateSelection,"88888")
          this.toggleSalesTab(3)
          this.toggleSalesVolumeTab(3)
      }
    },
    // 图表1  天数切换并显示相应数据
    toggleSalesTab(index) {
      this.salesTabIndex = index;
    },
    //  图表2  天数切换并显示相应数据
    toggleSalesVolumeTab(index) {
      this.salesVolumeTabIndex = index;
    },
    //时间选择
    dateSelectionFun(){
      this.start_date = this.dateSelection[0]
      this.end_date = this.dateSelection[1]
      console.log(this.dateSelection)
    },

    //   设置排序方式
    changeOrder(val) {
      this.orderType = val.prop;
      console.log(val)
      if (val.order === "ascending") {
        this.sortType = "asc";
      } else if (val.order === "descending") {
        this.sortType = "desc";
      } else {
        this.sortType = "";
      }
    },
    //     显示主推品类列表
    getMainTableList() {
      let param = {
        userid: this.author_id, //ID
        start_date:this.dateSelection[0], //开始时间
        end_date:this.dateSelection[1] //结束时间
      };
      if (this.goodsType) {
        param.category_type = this.goodsType_two; 
      }
      if (this.fanTabIndex) {
        param.type = this.fanTabIndex;
      }
      if (this.orderType) {
        param.sort = this.orderType; //需要进行排序的字段
      }
      if (this.sortType) {
        param.order_by = this.sortType; //排序方式
      }
      showSellGoods(param).then((res) => {
          console.log('res1', res);
         this.taskList = res.data.category_data;
      });
    },
    //获取销售趋势图表数据
    getSalesEcharts() {
      let param = {
        userid: this.author_id,
      };
      if (this.salesTabIndex) {
        param.type = this.salesTabIndex;
      }
      showSellGoods(param).then((res) => {
        if(res.data.sale_data.volume_chart == ''){
          this.salesEchartData = ''
        }
        this.salesEchartData = res.data.sale_data.volume_chart;
         for(let i=0;i<this.salesEchartData.length;i++){
          this.salesEchartData[i].date = this.salesEchartData[i].date.slice(0,4)+"-"+this.salesEchartData[i].date.slice(4,6)+"-"+this.salesEchartData[i].date.slice(6)
        }
        this.salesTrendEcharts();
      });
    },
    //获取销售额趋势图表数据
    getSalesVloumeEcharts() {
      let param = {
        userid: this.author_id,
      };
      if (this.salesVolumeTabIndex) {
        param.type = this.salesVolumeTabIndex;
      }
      showSellGoods(param).then((res) => {
         if(res.data.sale_data.amount_chart == ''){
          this.salesVolumeEchartData = ''
        }
        this.salesVolumeEchartData = res.data.sale_data.amount_chart;
        for(let i=0;i<this.salesVolumeEchartData.length;i++){
          this.salesVolumeEchartData[i].date = this.salesVolumeEchartData[i].date.slice(0,4)+"-"+this.salesVolumeEchartData[i].date.slice(4,6)+"-"+this.salesVolumeEchartData[i].date.slice(6)
        }

        this.salesVolumeEcharts();
      });
    },
    mounted(){
        this.getSalesEcharts();
    },
    //初始化
    init() {
      this.getMainTableList();
       this.getSalesEcharts();
      this.getSalesVloumeEcharts();
      this.salesTrend = this.$echarts.init(
        document.getElementById("sales-main-echarts")
      );
      this.salesVolume = this.$echarts.init(
        document.getElementById("salesVolume-main-echarts")
      );
      let resizeTimer = null;
      const self = this;
      window.addEventListener("resize", function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          self.salesTrend.resize();
          self.salesVolume.resize();
        }, 500);
      });
    },
    // 销量趋势图表
    salesTrendEcharts() {
       if(this.salesEchartData == ''){
          const dom = document.getElementById('sales-main-echarts');
          dom.innerHTML = '-暂无相关数据-';
          dom.style.cssText = 'text-align:center; color: #999; border: none;line-height: 400px';
          dom.removeAttribute('sales-main-echarts');
      }
      let time = [];
      let value = [];
      for (let i in this.salesEchartData) {
        var str = this.salesEchartData[i].date;
        var item = this.salesEchartData[i].second;
        time.push(str);
        value.push(item);
      }

      this.salesTrend = this.$echarts.init(
        document.getElementById("sales-main-echarts")
      );

      this.salesTrend.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              textStyle: {
                color: "#ccc",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#ccc",
              },
            },
            data: time,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#ccc",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#111",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#ccc",
              },
            },
          },
        ],
        series: [
          {
            color: "rgb(255,144,27)",
            name: "视频销量",
            type: "line",
            stack: "Total",
            data: value,
          },
        ],
      });
    },
    //     销售额趋势图表
    salesVolumeEcharts() {
        if(this.salesVolumeEchartData == ''){
          const dom = document.getElementById('salesVolume-main-echarts');
          dom.innerHTML = '-暂无相关数据-';
          dom.style.cssText = 'text-align:center; color: #999; border: none;line-height: 400px';
          dom.removeAttribute('salesVolume-main-echarts');
      }

      let time = [];
      let value = [];
      for (let i in this.salesVolumeEchartData) {
        var str = this.salesVolumeEchartData[i].date;
        var item = this.salesVolumeEchartData[i].second;
        time.push(str);
        value.push(item);
      }

      this.salesVolume = this.$echarts.init(
        document.getElementById("salesVolume-main-echarts")
      );
      this.salesVolume.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              textStyle: {
                color: "#ccc",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#ccc",
              },
            },
            data: time,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#ccc",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#111",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#ccc",
              },
            },
          },
        ],
        series: [
          {
            color: "rgb(255,144,27)",
            name: "视频销量",
            type: "line",
            stack: "Total",
            data: value,
          },
        ],
      });
    },
  },
  watch: {
    goodsType: function (val) {
      this.getMainTableList();
    },
    fanTabIndex: function (val) {
      this.getMainTableList();
    },
    sortType: function (val) {
      this.getMainTableList();
    },
    salesTabIndex: function (val) {
      this.getSalesEcharts();
    },
    salesVolumeTabIndex: function (val) {
      this.getSalesVloumeEcharts();
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-container {
  padding: 0 20px;
  .data-trend {
    margin-top: 20px;

    .data-menu-box {
      display: flex;
      justify-content: space-between;

      .data-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        text-align: center;

        .item {
          cursor: pointer;
          width: 50px;
          height: 30px;
          background: #f1f0fe;
          display: flex;
          justify-content: center;
          align-items: center;

          &.left {
            border-radius: 5px 0px 0px 5px;
          }

          &.right {
            border-radius: 0px 5px 5px 0px;
          }
        }

        .selected {
          color: #ffffff;
          background: #564ced;
        }
      }
    }
    .table-selected {
      .table-tabs {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .top-tab {
          cursor: pointer;
        }
      }
      .active {
        color: #564ced;
      }
    }
    .sales-echarts {
      width: 100%;
      height: 430px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    ::v-deep.tooltip {
      height: 59px;
      border-radius: 2px;
      box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
      background: #fff;

      .tooltip-box {
        padding: 13px 20px 10px;
      }

      .tooltip-title {
        font-size: 12px;
        color: #999999;
      }

      .tooltip-title-right {
        float: right;
        padding-right: 10px;
        font-size: 16px;

        &.blue {
          color: #564ced;
        }
      }

      .tooltip-title-left {
        color: #333;
      }
    }
  }
}
</style>